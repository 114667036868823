import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { EDPageLoader, EEDAnimation, error } from "@trailblazer-game/ed-webkit";
import Home_Header from "../Components/Home_Header";
import Home_Banner from "../Components/Home_Banner";
import Home_Separator from "../Components/Home_Separator";
import Home_Carousel from "../Components/Home_Carousel";
import Home_PlayNow from "../Components/Home_PlayNow";
import { useEffect, useState } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../../Theme/Theme";
import { ELeagueActions } from "../../League/Public/League_State";
import { log, warn } from "../../../../helpers/utils/log";
import { ClientLeagueAPI } from "@trailblazer-game/leaderboards-sdk";
import { Box } from "@mui/material";
const clientLeagueAPI = new ClientLeagueAPI();

interface IHomeProps {
  className?: string;
}

const EoLHome = ({ className }: IHomeProps) => {
 

  return (
    <Stack
      className={`${className} ${EEDAnimation.FadeIn}`}
      spacing={2}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Home_Separator title={" Important Notice"} />
      <Home_Header seasonName={"EoL"} />
      <Home_Separator title={""}/>

    </Stack>
  );
};

const EoLHomeStyled = styled(EoLHome)(() => ({
  overflow: "hidden",
  width: "100%",
}));

// Export
export default EoLHomeStyled;
