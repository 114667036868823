import { EEDAnimation } from "@trailblazer-game/ed-webkit";
import React, { useRef, useState } from "react";
import { useVisible } from "./useVisible";

export interface IWithLazyLoadProps {
    isVisible: boolean;
}

export function withLazyLoad<T extends IWithLazyLoadProps = IWithLazyLoadProps>(Component: React.ComponentType<T>, animated: boolean = true) {
    const WithLazyLoadComponent = (props: Omit<T, keyof IWithLazyLoadProps>) => {
        const ref = useRef(null);
        const isRefVisible = useVisible(ref);

        return <Component {...(props as T)}
                          className={animated && isRefVisible ? EEDAnimation.FadeIn : ""}
                          ref={ref}
                          isVisible={isRefVisible}
                          sx={{
                              visibility: isRefVisible ? "inherit" : "hidden",
                              ".fade-in": {
                                  animationName: isRefVisible ? "inherit" : "none !important"
                              }
                          }}
        />
    };

    return WithLazyLoadComponent;
}