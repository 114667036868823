import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import {
  active,
  EDButton,
  EDTypography,
  error,
  grey,
  spacing,
  warning,
} from "@trailblazer-game/ed-webkit";
import { useRouter } from "next/router";
import { EPage } from "../../../../helpers/constants";
import Box from "@mui/material/Box";
import { useParallax } from "react-scroll-parallax";
import {
  IWithLazyLoadProps,
  withLazyLoad,
} from "../../../../hooks/withLazyLoad";
import { forwardRef, memo } from "react";
import { theme } from "../../../Theme/Theme";

interface IHomeHeaderProps extends IWithLazyLoadProps {
  className?: string;
  seasonName: string;
}

const HomeHeader = memo(
  forwardRef(({ className, seasonName }: IHomeHeaderProps, ref) => {
    const router = useRouter();
    const { ref: dragonsRef } = useParallax<HTMLDivElement>({
      speed: 10,
      translateY: [0, -100, "easeInCirc"],
      translateX: [50, 50],
    });

    const { ref: contentRef } = useParallax<HTMLDivElement>({
      speed: 5,
      translateY: [0, 50, "easeInCirc"],
    });

    return (
      <Stack
        className={className}
        alignItems={"center"}
        justifyContent={"center"}
        sx={{ maxWidth: "100%" }}
        spacing={0}
        ref={ref}
      >
        <Box className={`dragons`} ref={dragonsRef} />

        <Stack
          className={className}
          spacing={2}
          alignItems={"center"}
          justifyContent={"center"}
          sx={{ position: "relative", zIndex: 100 }}
          ref={contentRef}
        >
          <Stack
            justifyContent={"center"}
            alignItems={"center"}
            spacing={2}
            style={{
              width: "100%",
              padding: "2rem",
              backgroundColor: error("main"),
              color: grey(900)
            }}
          >
            <EDTypography variant="body1" align={"center"}>
              Dear Dragoneers,<br></br>
              Almost two years, after announcing Eternal Dragons, we have made the difficult decision to stop all development on the project. This includes the development of the mobile game beta, as well as any surrounding efforts related to the wider ecosystem.<br></br>
              <br></br> Eternal Dragons was our very personal dream, and we have been continuously inspired and humbled by the community joining us in this journey. For those of you who have supported Eternal Dragons, we want to extend our deepest gratitude for your loyalty, dedication and unwavering support and enthusiasm. The insights and learnings acquired through this journey have been invaluable. For this, we thank you deeply.<br></br>
              <br></br> If you want to read up on the details of the decision, or catch up on the journey of Eternal Dragons, please check out  <a style={{ color: grey(50) }} href="https://eternaldragons.medium.com/" target="_blank" rel="noopener noreferrer">the Eternal Dragons Medium page</a>.<br></br>
            </EDTypography>
          </Stack>

        </Stack>
      </Stack>
    );
  })
);

HomeHeader.displayName = "HomeHeader";

const HomeHeaderStyled = styled(HomeHeader)(() => ({
  paddingTop: spacing(2),
  overflow: "visible",
  width: "100%",
  paddingLeft: spacing(4),
  paddingRight: spacing(4),
  maxWidth: "1440px",
  ".dragons": {
    position: "absolute",
    margin: "0 !important",
  //  top: `0`,
    left: `-50%`,
    width: "100%",
    maxWidth: "1920px",
    minHeight: "1600px",
    backgroundImage: "url(/home/dragons.webp)",
    backgroundSize: "cover",
    transform: "translateX(50%)",
    backgroundPosition: "50% 0",
    backgroundRepeat: "no-repeat",
  },
  ".title": {
    color: active("primary"),
    textShadow: `1px 1px 6px black`,
    maxWidth: "450px",
  },
  ".video-player": {
    aspectRatio: "16/9",
    width: "100%",
    borderRadius: 8,
    maxWidth: "600px",
  },
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(0, 4),
    ".dragons": {
      minHeight: "100vh",
      width: "100vw",
    },
  },
}));

// Export
export default withLazyLoad(HomeHeaderStyled);
