import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import { EDTypography } from "@trailblazer-game/ed-webkit";
import React, { forwardRef, memo } from "react";
import { theme } from "../../../Theme/Theme";
import { IWithLazyLoadProps, withLazyLoad } from "../../../../hooks/withLazyLoad";

interface IHomeSeparatorProps extends IWithLazyLoadProps {
  className?: string;
  title: string;
}

const HomeSeparator = memo(forwardRef(({ className, title }: IHomeSeparatorProps, ref) => {
  return <Stack className={className} direction={"row"} alignItems={"center"} justifyContent={"center"} ref={ref}>
    <EDTypography variant={"h3"} align={"center"}>{title}</EDTypography>
  </Stack>;
}));

HomeSeparator.displayName = "HomeSeparator";

const HomeSeparatorStyled = styled(HomeSeparator)(() => ({
  position: "relative",
  zIndex: 10,
  padding: theme.spacing(0, 48),
  background: "url(/home/separator-background.webp),url(/home/separator-background-1.webp)",
  backgroundPosition: "0%, 100%",
  backgroundRepeat: "no-repeat, no-repeat",
  backgroundSize: "auto 24px, auto 24px",
  height: "64px",
  maxWidth: "1440px",
  [theme.breakpoints.down("md")]: {
    background: "none",
    padding: 0
  }
}));

// Export
export default withLazyLoad(HomeSeparatorStyled);