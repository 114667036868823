import React from 'react';
import {
  EDPageBackground,
  EDPageContainer,
  EDPageContent,
  EDPageParallaxBackground,
  IEDPageParallaxBackgroundImage
} from "@trailblazer-game/ed-webkit";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../Theme/Theme";

export interface IPageLayoutProps {
  children: React.ReactNode | React.ReactNode[]
  className?: string
  backgroundImage?: string
  backgroundImages?: IEDPageParallaxBackgroundImage[];
  backdropFilter?: string;
  title?: string;
  backgroundColor?: string | null;
  backgroundStyles?: object;
  justifyContent?: string;
  alignItems?: string;
  fillWidth?: boolean;
}

function PageLayout({
                      backgroundStyles,
                      backgroundImage,
                      backgroundImages,
                      backgroundColor,
                      backdropFilter,
                      justifyContent,
                      alignItems,
                      children,
                      className,
                      title,
                      fillWidth
                    }: IPageLayoutProps) {
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <EDPageContainer sx={{ overflow: isSmall ? "hidden" : "visible" }} className={className}>
      {backgroundImage ? <EDPageBackground src={backgroundImage || ""} in={true}
                                           sx={{ ".background": { ...backgroundStyles } }}/> : <></>}
      {backgroundImages ?
        <EDPageParallaxBackground images={backgroundImages || []} in={true} title={title || ""}/> : <></>}
      <EDPageContent sx={{
        backgroundColor: backgroundColor || 'transparent',
        backdropFilter: backdropFilter || 'none',
        padding: fillWidth ? 0 : theme.spacing(12, 4),
        ".content-container": {
          maxWidth: fillWidth ? "1920px" : "1440px",
        },
        [theme.breakpoints.down('md')]: {
          padding: theme.spacing(2, fillWidth ? 0 : 2),
        },
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2, fillWidth ? 0 : 2),
        }
      }}
        justifyContent={justifyContent || 'center'}
        alignItems={alignItems || 'center'}>
        {children}
      </EDPageContent>
    </EDPageContainer>
  );
}


// Default style!
const PageLayoutStyled = styled(PageLayout)({});

// Export
export default PageLayoutStyled;
