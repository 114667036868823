import * as React from "react";
import { EDPageLoader } from "@trailblazer-game/ed-webkit";
import dynamic from "next/dynamic";
import PageLayout from "../components/Shared/PageLayout";
import EoLHomeStyled from "../components/Pages/Home/Public/EoLHome";


export default function HomePage() {
  return <PageLayout
    justifyContent={"flex-start"}
    backgroundImage={"/home/home-bg.webp"}
    fillWidth={true}>
    <EoLHomeStyled/>
  </PageLayout>
}

HomePage.metaData = {
  title: 'Eternal Dragons - Unleash Legendary Creatures in Epic Battles',
  description: "Command a legion of dragons and minions in this thrilling auto-battler game. Engage in strategic warfare, collect powerful creatures, and conquer formidable foes. Download now and ascend to the pinnacle of dominance!"
}